const environment = process.env.REACT_APP_ENV;
const envType = environment === `production` ? `production` : `staging`;
const isProduction = envType == "production";

// console.log("environment",environment);

//GENERAL VALUES
export const WEB_SITE_NAME = "Insurance Motors";
export const DEBUG_MODE = !isProduction;
export const DATE_FORMAT = "DD/MM/yyyy HH:mm"
export const PICKER_DATE_FORMAT = "dd/MM/yyyy HH:mm"
export const PRIMARY_COLOR = "#f05138";

//URLS
export const WEB_SITE_URL = "https://www.insurancemotors.eu/";
export const API_URL = DEBUG_MODE ? "https://devapi.insurancemotors.eu/v1/" : "https://api.insurancemotors.eu/v1/";


export const LIGHTS_MODE = "YOU_WAY_APP_LIGHTS_MODE_VALUE";


//DATES
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

//IDS
export const SOURCE_TWITTER_ID = "";

//OPTIONS
export const SLUG_OPTIONS = {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: /[*+~.()/'"!:@,]/g, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: false,     // strip special characters except replacement, defaults to `false`
    locale: 'vi',       // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
};

export const SEARCH_TYPE_EXACT = "exact";
export const SEARCH_TYPE_EXPANDED = "expanded";
export const SEARCH_TYPE_FUZZY = "fuzzy";


export const SOCIAL_IMAGE_TEMPLATES = [
    {
        id: 1,
        type: 'NEW',
        bgColorOne: "#ff7b35",
        bgColorTwo: "#ea4849",
        icon: "/social_icons/new.png"
    },
    {
        id: 2,
        type: 'HIDDEN GEM',
        bgColorOne: "#2b97ee",
        bgColorTwo: "#12e9fe",
        icon: "/social_icons/gem.png"
    },
    {
        id: 3,
        type: 'VIDEO',
        bgColorOne: "#dd2838",
        bgColorTwo: "#3e2878",
        icon: "/social_icons/video.png"
    },
    {
        id: 4,
        type: 'PODCAST',
        bgColorOne: "#c0bc37",
        bgColorTwo: "#369c60",
        icon: "/social_icons/podcast.png"
    },
    {
        id: 5,
        type: 'BOOK',
        bgColorOne: "#101b2e",
        bgColorTwo: "#305275",
        icon: "/social_icons/book.png"
    }
]