import React, { useState,useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { postChangePassword } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { POST_CHANGE_PASSWORD } from '../../redux/actionTypes';
import {logo_letters_red} from '../../images';
import { useParams } from 'react-router-dom';


const ChangePassword = (props) => {


//Then inside your component
const { resetPassToken } = useParams();


const dispatch = useDispatch();
const settingsData = useSelector(({settings}) => settings.settingsData);
const changePasswordData = useSelector(({auth}) => auth.changePasswordData);
const [loading,setLoading] = useState(false);
const trans = settingsData.trans;

useEffect(() => {
    if (changePasswordData != null) {
        if (changePasswordData.status) {
          window.location.replace('/');
        } else {
            setLoading(false);
            toast.dismiss();
            toast.error(changePasswordData.message, {
                position: toast.POSITION.TOP_RIGHT
                });
        }
        dispatch({type: POST_CHANGE_PASSWORD, payload: null});
    } 
}, [changePasswordData]);




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={WEB_SITE_URL}>
        {WEB_SITE_NAME}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


  const handleSubmit = (event) => {
    event.preventDefault();
   
    const data = new FormData(event.currentTarget);
    let repassword = data.get('repassword');
    let password = data.get('password');

    setLoading(true);

    let postData = {
      verifyPassword: repassword,
      newPassword: password,
      token: resetPassToken
    };

    dispatch(postChangePassword(postData))
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           <img src={logo_letters_red} width="340px" alt="logo" style={{margin:"10px"}}/>
      
         
          <Typography component="h1" variant="h7">
            {trans.change_password_title}
          </Typography>
          <div style={{marginTop: "10px", marginBottom: "10px"}}>
            {trans.change_password_desc}
          </div>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
         
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={trans.change_password_pass_one_hint}
              type="password"
              id="password"
              autoComplete={null}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                name="repassword"
                label={trans.change_password_pass_two_hint}
                type="password"
                id="repassword"
                autoComplete={null}
              />
        

             <LoadingButton 
                loading={loading}    
                type="submit"
                fullWidth
                variant="contained"
                style={{textTransform:'none'}}
                sx={{ mt: 3, mb: 2 }}>
                {trans.change_password_button}
              </LoadingButton>
           
           
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box> */}
       
      </Container>
  );
}

export default ChangePassword;