import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_USER_INFO,
  POST_CHANGE_PASSWORD,
  POST_VERIFY_EMAIL
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  signInData: null,
  logoutData: null,
  userInfoData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_SIGN_IN:
                   return {
                     ...state,
                     signInData: action.payload
          }
          case POST_SIGN_OUT:
            return {
              ...state,
              logoutData: action.payload
          }
          case POST_USER_INFO:
            return {
              ...state,
              userInfoData: action.payload
          }
          case POST_CHANGE_PASSWORD:
            return {
              ...state,
              changePasswordData: action.payload
          }
          case POST_VERIFY_EMAIL:
            return {
              ...state,
              verifyEmailData: action.payload
          }
         
         
        default:
              return state;
        }
};
      
export default reducer;