import React, { useState,useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { postVerifyEmail } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { POST_VERIFY_EMAIL } from '../../redux/actionTypes';
import {logo_letters_red, img_verification} from '../../images';
import { useParams } from 'react-router-dom';
import GridLoader from "react-spinners/GridLoader";



const VerifyEmail = (props) => {


//Then inside your component
const { verifyToken } = useParams();


const dispatch = useDispatch();
const settingsData = useSelector(({settings}) => settings.settingsData);
const verifyEmailData = useSelector(({auth}) => auth.verifyEmailData);
const [loading,setLoading] = useState(true);
const [verifyMessage,setVerifyMessage] = useState("");
const trans = settingsData.trans;


  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(() => {
    if (verifyEmailData != null) {
        if (verifyEmailData.status) {
          setLoading(false);
          setVerifyMessage(verifyEmailData.message);
        } else {
            setLoading(false);
            toast.dismiss();
            toast.error(verifyEmailData.message, {
                position: toast.POSITION.TOP_RIGHT
                });
        }
        dispatch({type: POST_VERIFY_EMAIL, payload: null});
    } 
  }, [verifyEmailData]);


  

  const handleSubmit = () => {
   

    setLoading(true);

    let postData = {
      verifyToken: verifyToken
    };

    dispatch(postVerifyEmail(postData))
  };

  return (
      <Container component="main" maxWidth="xs">
           <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           <img src={logo_letters_red} width="100px" alt="logo" style={{margin:"10px"}}/>
      
         
          <Typography component="h1" variant="h7">
            <div style={{marginTop: "20px"}}>
              {trans.verify_email_title}
            </div>  
          </Typography>
       
         {loading ? <Box noValidate sx={{ mt: 1 }}>
            <div style={{marginTop: "20px"}}>
              <GridLoader
                    color={"black"}
                    loading={loading}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
            </div>   
           </Box> :
          <Box noValidate sx={{ mt: 1 }}>

            <div style={{display:'flex',flexDirection: "column",justifyItems: 'center',alignItems:"center"}}>

              <br/>

              <img style={{width:'150px'}} src={img_verification}/>

              <br/>

              <div style={{marginTop: "10px", marginBottom: "10px"}}>
                {verifyMessage}
              </div>

            </div>
         

          </Box>
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          
        </Box>
      </Container>
  );
}

export default VerifyEmail;