import {
  GET_SETTINGS,
  GET_COUNTRIES
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
  
  export const getSettings = () => async dispatch => {
      //Get user locale of the machine he is on
    const userLocale = General.getLocale();

    let postData = {
      "langLoc": userLocale,
      "isAdmin": false
  };

    const response = await axios.post(`settings`,postData);
    if (DEBUG_MODE){
      console.log(response.data);
    }
  
    dispatch({type: GET_SETTINGS, payload: response.data});
  };


  export const getCountries = () => async dispatch => {
    //Get user locale of the machine he is on
  const userLocale = General.getLocale();

  let postData = {
    "langLoc": userLocale,
    "isAdmin": false
  };

  const response = await axios.post(`settings/countries`,postData);
  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: GET_COUNTRIES, payload: response.data});
};